<template>
  <div class="MainStepsForm">
    <div class="container">
      <a-alert message="平台为你自行上传的作品进行版权存证，不对您上传内容的真实性进行验证" type="warning" show-icon />

      <div class="commonWhiteBg">
        <!-- <div class="ant-popover-arrow"></div> -->
        <div class="title">版权登记</div>

          <el-form ref="form" :model="form" :rules="rules" label-width="125px">
            <div class="formTitle">权利人信息</div>
            <a-row>
              <a-col :md="24" :lg="8" :xl="8">
                <el-form-item label="著作权人" prop="right">
                  <span class="rightText" v-for="(author, index) in form.right" :key="index">{{author.obligeeType == "1"? author.userName : author.companyName}}&nbsp;</span>
                  <el-button type="text" @click="isRightDialogVisible = true" :disabled="stepIdx=='2'">选择著作权人</el-button>
                  <choose-person-dialog
                      title="选择著作权人"
                      v-if="isRightDialogVisible"
                      v-model="form.right"
                      :listOpt="obligees"
                      :linkObj="{ label: '添加著作权人', path: '/Mine/Obligee' }"
                      @close="onSetRightDialogClose"
                      @input="onChooseRight"
                  ></choose-person-dialog>
                </el-form-item>
              </a-col>
              <a-col :md="24" :lg="8" :xl="8">
                <el-form-item label="作者同著作权人" prop="rightSame">
                  <el-radio-group v-model="rightSame" @change="changeRightSame" :disabled="stepIdx=='2'">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="2">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </a-col>
              <a-col :md="24" :lg="8" :xl="8">
                <div v-for="(item,index) in form.authors" :key="index">
                  <el-form-item label="作者" :prop="'authors['+index+'].name'" :rules="{ required: true, message: '请输入作者名称', trigger: 'blur' }" v-if="rightSame == 2">
                    <el-input placeholder="请输入作者名称" v-model="item.name" :disabled="stepIdx=='2'"></el-input>
                  </el-form-item>
                </div>
              </a-col>
            </a-row>
            

            <div class="formTitle">作品信息</div>
            <el-form-item label="上传作品" ref="uploader" class="uploadWraper" prop="files">
            <el-upload  class="Uploader" :action="uploadUrl" :headers="uploadHeaders" :on-success="uploadSuccess"
                :before-upload="uploadBefore" multiple :file-list="fileList" :disabled="stepIdx=='2'">
              <el-button type="primary" :disabled="stepIdx=='2'"><i class="iconfont iconUpload">&#xe606;</i>上传作品文件</el-button>
            </el-upload>
            <!-- <p class="tipsText" type="warning">支持扩展名：.doc .docx .pdf .jpg .png ...</p> -->
            </el-form-item>

            <a-row>
              <a-col :md="24" :lg="8" :xl="8">
                <el-form-item label="作品名称" prop="subject">
                  <el-input placeholder="请输入作品名称" v-model="form.subject" :disabled="stepIdx=='2'"></el-input>
                </el-form-item>
              </a-col>
              <a-col :md="24" :lg="8" :xl="8">
                <el-form-item label="作品创作类型" prop="worksType">
                  <el-select v-model="worksTypeText" placeholder="请选择创作类型" @change="selectWorksType" :disabled="stepIdx=='2'">
                      <el-option v-for="(item, index) in constants.worksType" :key="index" :label="item.label" :value="index"></el-option>
                  </el-select>
                </el-form-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :md="24" :lg="8" :xl="8">
                <el-form-item label="创作完成时间" prop="finishTime">
                  <el-date-picker v-model="form.finishTime" placeholder="选择日期时间" type="date" format="yyyy-MM-dd" value-format="timestamp" :picker-options="pickerOptionsStart" :disabled="stepIdx=='2'"></el-date-picker>
                </el-form-item>
              </a-col>
              <a-col :md="24" :lg="8" :xl="8">
                <el-form-item label="创作完成地点" prop="province">
                  <china-area-data :areaData="areaData1" @change="selectArea" :disabled="stepIdx=='2'"></china-area-data>
                </el-form-item>
              </a-col>
              <a-col :md="24" :lg="8" :xl="8"></a-col>
            </a-row>

            <a-row>
              <a-col :md="24" :lg="8" :xl="8">
                <el-form-item label="作品发表状态" prop="publishStatus">
                  <el-select v-model="publishStatusText" placeholder="请选择作品发表状态" @change="selectPublishStatus" :disabled="stepIdx=='2'">
                      <el-option v-for="(item, value) in constants.publishStatus" :key="value" :label="item.label" :value="value"></el-option>
                  </el-select>
                  </el-form-item>
              </a-col>
              <a-col :md="24" :lg="8" :xl="8">
                <el-form-item label="首次发表时间" prop="publishTime" v-if="form.publishStatus == 2">
                  <el-date-picker v-model="form.publishTime" placeholder="选择日期时间" type="date" format="yyyy-MM-dd" value-format="timestamp" :picker-options="pickerOptionsEnd" :disabled="stepIdx=='2'"></el-date-picker>
                </el-form-item>
              </a-col>
              <a-col :md="24" :lg="8" :xl="8">
                <el-form-item label="发表地点" prop="province2" v-if="form.publishStatus == 2">
                  <china-area-data :areaData="areaData2" @change="selectArea2" :disabled="stepIdx=='2'"></china-area-data>
                </el-form-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :md="24" :lg="12" :xl="12">
                <el-form-item label="内容简介" prop="creationIntention.text">
                  <el-input type="textarea" :rows="5" placeholder="请输入内容简介" v-model="form.creationIntention.text" :disabled="stepIdx=='2'"></el-input>
                </el-form-item>
              </a-col>
              <a-col :md="24" :lg="12" :xl="12">
                <a-alert class="tips" type="warning">
                  <div slot="message">温馨提示：</div>
                  <div slot="description">
                  请填写中心内容及作品特点：对作品进行简述，包括主要内容、章节编排、部分划分、与同类作品相比的独特之处等。如美术作品需对作品本身的表现形式和审美特征进行描述（颜色、形态、表情等）。(注：若提交的作品样本中含有外文请在内容简介中附中文解释。)
                  </div>
                </a-alert>
              </a-col>
            </a-row>

            <a-row>
              <a-col :md="24" :lg="12" :xl="12">
                <el-form-item label="创作过程" prop="creationOriginality.text">
                  <el-input type="textarea" :rows="5" placeholder="请输入创作过程" v-model="form.creationOriginality.text" :disabled="stepIdx=='2'"></el-input>
                </el-form-item>
              </a-col>
              <a-col :md="24" :lg="12" :xl="12">
                <a-alert class="tips" type="warning">
                  <div slot="message">温馨提示：</div>
                  <div slot="description">
                  （1）创作过程：创作意图，策划和准备，创作方式（手绘、使用电脑、具体的艺术手法、软件及具体操作等），经过几次修改，每次都修改了哪些内容。 （2）使用情况：发表情况、许可他人专有使用情况。 （注：摄影作品须在创作过程中写明具体拍摄设备、光圈值、快门等详细参数。）
                  </div>
                </a-alert>
              </a-col>
            </a-row>

            <el-form-item label="其它证明材料" prop="ownershipPicture.additionalProp2">
              <div class="uploadBusinessLicense">
                <el-upload class="Uploader" :limit="1" :action="uploadUrl"  :headers="uploadHeaders" :on-success="uploadSuccess2" :on-remove="(file, fileList2) => {handleRemove2(file, fileList2)}"
                :before-upload="uploadBefore2" multiple :file-list="fileList2" :disabled="stepIdx=='2'">
                  <el-button type="primary" :disabled="stepIdx=='2'"><i class="iconfont iconUpload">&#xe606;</i>上传附件资料</el-button>
                </el-upload>
              </div>
            </el-form-item>

            <div class="formTitle formTitle2"></div>
            <el-form-item label="办理时限" prop="processingTime">
              <el-radio-group v-model="form.extra.processingTime" @change="changeProcessingTime" :disabled="stepIdx=='2'">
                <el-radio label="15" v-if="stepIdx=='1' || form.extra.processingTime == '15'">15个工作日(正常)</el-radio>
                <el-radio label="5" v-if="stepIdx=='1' || form.extra.processingTime == '5'">5个工作日(加急档)</el-radio><br>
                <el-radio label="3" v-if="stepIdx=='1' || form.extra.processingTime == '3'">3个工作日(加急档)</el-radio>
                <el-radio label="1" v-if="stepIdx=='1' || form.extra.processingTime == '1'">1个工作日(加急档)</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="支付方式" prop="payStyle" class="choosePayStyle">
              <el-radio-group v-model="form.paymode" @change="chengePaymode" :disabled="stepIdx=='2'">
                <el-radio label="vip" v-if="stepIdx=='1' || form.paymode == 'vip'"><span class="onlyText"> 会员权益支付（当前登记次数可用{{vipInfo ? vipInfo.registerCount : 0}}次）</span></el-radio>
                <el-radio label="alipay" v-if="stepIdx=='1' || form.paymode == 'alipay'"><i class="iconfont aliPayColor"> &#xe675; </i><span>支付宝支付</span></el-radio>
                <el-radio label="wechat" v-if="stepIdx=='1' || form.paymode == 'wechat'"><i class="iconfont weChatColor"> &#xe64f; </i><span>微信支付</span></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="支付金额" v-if="form.paymode != 'vip' && stepIdx=='2'" class="payMount">
              <div><em class="bigPrice"> {{ orderAmount }} </em> 元</div>
            </el-form-item>

            <el-form-item class="submitRow">
              <el-button type="primary" @click="reset" v-if="stepIdx==1">重置</el-button>
              <el-button type="primary" @click="next" v-if="stepIdx==1">下一步</el-button>
              <el-button type="primary" @click="prev" v-if="stepIdx==2">返回修改</el-button>
              <el-button type="primary" @click="onSubmit" v-if="stepIdx==2">提交信息</el-button>
            </el-form-item>

          </el-form>

      </div>
    </div>

    <pay-dialog ref="payDialog" payForm="register" @close="onSetPayDialogClose"></pay-dialog>
  </div>
</template>

<script>
import { queryExistOrderItems,applyRegisterOrder,queryDuplicate } from "@/api/EvidenceService";
import { queryObligeeList } from "@/api/ObligeeService";
import multipleSelect from "../../../components/form/multipleSelect";
import choosePersonDialog from "../../../components/form/choosePersonDialog";
import ChinaAreaData from "@/components/ChinaAreaData";
import { mapGetters } from "vuex";
import { mixinsScrollErrorPosition } from "../../../mixins";
import constants from "@/utils/constants";
import PayDialog from "../../../components/PayDialog";

export default {
  name: "RegisterInputForm",
  mixins: [mixinsScrollErrorPosition],
  components: {
    multipleSelect,
    choosePersonDialog,
    ChinaAreaData,
    PayDialog,
  },
  data() {
    return {
      constants,
      stepIdx: "1",

      uploadUrl:`${process.env.VUE_APP_BASEURL}/v1/ins/file/upload?path=exist/evidence/files/`,
      uploadHeaders:{ 
        'Authorization': this.$store.getters.token,
        'Product-Mode': "READ",
        'Terminal-Code': "IPCHAIN2"+ this.$store.getters.terminalCode,
        'Terminal-Type': "WEB",
        'Merchant-Id': "5"
      },
      fileList: [],
      worksTypeText:'',
      publishStatusText: '',
      fileList2: [],
      areaData1: {},
      areaData2: {},
      orderAmount: 0,
      rightSame: "1",
      form: {
        paymode: 'wechat',
        right: [],
        creationNature: "1",
        creationPropertyDesc:{
          text:"",
        },
        finishTime: "",
        creationIntention:{
          text:"",
        },
        creationOriginality:{
          text:"",
        },
        province: "湖北省",
        city: "武汉市",
        publishStatus: "",
        province2: "湖北省",
        city2: "武汉市",
        publishTime: "",
        subject: "",
        worksType: "",
        files: [],
        ownership: "1",
        obtainType: "1",
        authors: [
          {name:"",signatureType:"1",signatureName:"",orders:1},
        ],
        obtainTypeDesc: {
          text:"",
        },
        ownershipPicture: {
          "additionalProp1": "",
          "additionalProp2": "",
          "additionalProp3": "",
          "additionalProp4": "",
          "additionalProp5": "",
          "additionalProp6": "",
        },
        rightHave: [],
        extra: {
          processingTime: '15',
        },
      },
      rules: {
        right: [
          { required: true, message: "请选择著作权人", trigger: "blur" },
        ],
        subject: [
          { required: true, message: '请输入作品名称', trigger: "blur" },
          { min: 3, max: 50, message: "长度在 3 到 50 个字符", trigger: "blur",},
          { 
            validator: (rule, value, callback) => {
              // 异步验证示例
              queryDuplicate(this.form.subject).then(data => {
                data.data.duplicate ? callback(new Error('经查询作品名称重复')) : callback();
              });
            },
            trigger: "blur"
          },
        ],
        province: [
          { required: true, validator: this.valiCity, trigger: "blur" },
        ],
        province2: [
          { required: true, validator: this.valiCity2, trigger: "blur" },
        ],
        files: [
          { required: true, message: "请上传作品", trigger: "blur" },
        ],
        worksType: [
          { required: true, message: "请选择作品创作类型", trigger: "blur" },
        ],
        finishTime: [
          { required: true, message: "请选择创作完成时间", trigger: "blur" },
        ],
        'creationIntention.text': [
          { required: true, message: "请输入内容简介", trigger: "blur" },
        ],
        'creationOriginality.text': [
          { required: true, message: "请输入创作过程", trigger: "blur" },
        ],
        publishStatus: [
          { required: true, message: "请选择作品发表状态", trigger: "blur" },
        ],
        publishTime: [
          { required: true, message: "请选择首次发表日期", trigger: "blur" },
        ],
        
      },
      //完成日期在发表日期之前,同时不能超过当前日期
      pickerOptionsStart: {
          disabledDate: time => {
              return time.getTime() > new Date(this.form.publishTime).getTime() || time.getTime() > Date.now()   //-8.64e7可以让开始时间和结束时间选同一天
          }
      },
      pickerOptionsEnd: {
          disabledDate: time => {
              return time.getTime() < new Date(this.form.finishTime).getTime() || time.getTime() > Date.now()     //-8.64e7可以让开始时间和结束时间选同一天
          }
      },
      isAuthorDialogVisible: false,
      isRightDialogVisible: false,

    };
  },
  computed: {
    ...mapGetters(["mid", "userInfo", "evidencePaymentPrice", "vipInfo"]),
  },
  created() {
    // localStorage.removeItem(`form_${this.mid}`); //测试清理缓存
    // 从 localStorage 中加载数据
    const savedData = localStorage.getItem(`form_${this.mid}`);
    let form = {};
    if (savedData) {
      form = JSON.parse(savedData);
      if(!form.extra){ // 判断是否存在processingTime参数，若是没有，添加默认15
        form.extra = {
          processingTime: '15',
        }
      }
      // console.log(form);
      this.form = form;
    }

    this.loadData();

    // 默认地址
    this.areaData1 = {
      province: this.form.province,
      city: this.form.city,
    },
    this.areaData2 = {
      province: this.form.province2,
      city: this.form.city2,
    }

    // 获取缓存里面信息转化
    if(this.form.worksType){
      this.worksTypeText = this.constants.worksType[this.form.worksType * 1 - 1].label;
    }
    if(this.form.files){
      let arr = []
      for(let i=0;i<this.form.files.length;i++){
        arr.push({
          name: this.form.files[i].sourceName,
          url: this.form.files[i].downloadUrl,
        })
      }
      this.fileList = arr
    }
    if(this.form.publishStatus){
      this.publishStatusText = this.constants.publishStatus[this.form.publishStatus * 1 - 1].label;
    }else{
      this.form.publishStatus = '1';
      this.publishStatusText = '未发表';
    }
    if(this.form.ownershipPicture.additionalProp2){
      let arr = []
      var lastSlashIndex = this.form.ownershipPicture.additionalProp2.lastIndexOf('/');  // 截取图片url上的文件名
      var fileName = this.form.ownershipPicture.additionalProp2.substring(lastSlashIndex + 1);
      arr.push({
        name: fileName,
        url: this.form.ownershipPicture.additionalProp2,
      })
      this.fileList2 = arr
    }


    // 有会员的默认选,会员支付方式
    setTimeout(()=>{
      if(this.vipInfo.registerCount > 0){
        this.form.paymode = 'vip';
      }
    },300)
    

  },
  watch: {
    form: {
      handler(newValue) {
        // 自动保存表单数据到 localStorage
        localStorage.setItem(`form_${this.mid}`, JSON.stringify(newValue));
      },
      deep: true
    }
  },
  methods: {
    loadData() {
      queryObligeeList(1, 100).then(({ data }) => {
        this.obligees = data.list;
      });
    },
    calAmount() {  // 计算价格
      let amount = 0;
      let registerConfig = this.evidencePaymentPrice.register;
      amount = registerConfig.amount * 1;

      this.orderAmount = amount;
    },
    onSetAuthorDialogClose() {
      this.isAuthorDialogVisible = false;
    },
    onSetRightDialogClose() {
      this.isRightDialogVisible = false;
    },
    onChooseAuthor(users) {
      this.form.author = users;
    },
    onChooseRight(users) {
      this.form.right = users;
      if(this.rightSame == "1"){ // 作者同著作权人
        if(this.form.right[0].obligeeType == '1'){
          this.form.authors = [{name: this.form.right[0].userName,signatureType:"1",signatureName: this.form.right[0].userName,orders:1}];
        }else{
          this.form.authors = [{name: this.form.right[0].companyName,signatureType:"1",signatureName: this.form.right[0].companyName,orders:1}];
        }
      }
      this.$refs.form.validateField('right');
    },
    changeRightSame(value){
      if(value == '1' && this.form.right.length > 0){ // 作者同著作权人
        if(this.form.right[0].obligeeType == '1'){
          this.form.authors = [{name: this.form.right[0].userName,signatureType:"1",signatureName: this.form.right[0].userName,orders:1}];
        }else{
          this.form.authors = [{name: this.form.right[0].companyName,signatureType:"1",signatureName: this.form.right[0].companyName,orders:1}];
        }
      }else if(value == '2' && this.form.right.length > 0){
        this.form.authors = [{name: '',signatureType:"1",signatureName: '',orders:1}];
      }
    },
    valiCity(rule, value, callback) {
      if (!this.form.province) {
        callback(new Error("请选择省份"));
        return;
      }
      if (!this.form.city) {
        callback(new Error("请选择城市"));
        return;
      }
      // if (!this.form.area) {
      //   callback(new Error("请选择区县"));
      //   return;
      // }
      callback();
    },
    valiCity2(rule, value, callback) {
      if (!this.form.province2) {
        callback(new Error("请选择省份"));
        return;
      }
      if (!this.form.city2) {
        callback(new Error("请选择城市"));
        return;
      }
      callback();
    },
    selectArea(data) {
      // console.log(data);
      if(data.laber == 'province'){
        this.form.province = data.value;
        this.form.city = '';
      }else if(data.laber == 'city'){
        this.form.city = data.value;
      }
      this.$refs.form.validateField('province');
      this.$refs.form.validateField('city');
      // console.log(this.form.province);
      // this.form.area = data.selectData.area.name;
    },
    selectArea2(data) {
      if(data.laber == 'province'){
        this.form.province2 = data.value;
        this.form.city2 = '';
      }else if(data.laber == 'city'){
        this.form.city2 = data.value;
      }
      this.$refs.form.validateField('province2');
      this.$refs.form.validateField('city2');
      // this.form.area2 = data.selectData.area.name;
    },
    // 上传作品
    uploadBefore(file) {
        const NoPassFileTypes=['.exe','.dmg','.js','.html','.json','.xml','.dtd','.jsp','.php','.asp','.aspx']
        const fileName=file.name
        const fileEnd=fileName.substring(fileName.indexOf("."))
				const isFileTypePass = !NoPassFileTypes.includes(fileEnd)
				const isLt500M = file.size / 1024 / 1024 < 500;

				if (!isFileTypePass) {
					this.$message.error('上传文件格式不能为'+fileEnd);
				}
				if (!isLt500M) {
					this.$message.error('上传文件大小不能超过 500MB!');
				}
				return isFileTypePass&&isLt500M;
			},
    uploadSuccess(response, file, fileList){
        this.form.files.push(response);
        this.$refs.form.validateField('files');
        this.calAmount(); // 计算价格
    },
    handleRemove(file,fileList){
      let that=this;
      for(let i=0;i<this.form.files.length;i++){
        if(file.name == this.form.files[i].sourceName){
          // console.log("i-------",i);
          that.form.files.splice(i,1);
        }
      }
      this.calAmount(); // 计算价格
    },
    // 上传其他证明材料
    uploadBefore2(file) {
      const isImage = file.type === 'image/jpeg' || file.type === 'image/png'; 
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isImage) {
        this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      return isImage && isLt2M;
    },
    uploadSuccess2(response, file) {
      this.form.ownershipPicture.additionalProp2 = response.downloadUrl;
      this.$refs.form.validateField('ownershipPicture.additionalProp2');
    },
    handleRemove2(file,fileList){
      this.form.ownershipPicture.additionalProp2 = '';
    },

    // 选择作品创作类型
    selectWorksType(index){
      this.worksTypeText = this.constants.worksType[index].label;
      this.form.worksType = this.constants.worksType[index].value;
      this.$refs.form.validateField('worksType');
      // console.log(this.form.worksType);
    },
    // 选择作品发表状态
    selectPublishStatus(index){
      this.publishStatusText = this.constants.publishStatus[index].label;
      this.form.publishStatus = this.constants.publishStatus[index].value;
      this.$refs.form.validateField('publishStatus');
    },

    // 选择办理时限
    changeProcessingTime(value){
      this.form.extra.processingTime = value;
    },

    // 选择支付方式
    chengePaymode(value){
      this.form.paymode = value;
    },


    // 重置
    reset(){
      this.form= {
        paymode: 'wechat',
        right: [],
        creationNature: "1",
        creationPropertyDesc:{
          text:"",
        },
        finishTime: "",
        creationIntention:{
          text:"",
        },
        creationOriginality:{
          text:"",
        },
        province: "湖北省",
        city: "武汉市",
        publishStatus: "",
        province2: "湖北省",
        city2: "武汉市",
        publishTime: "",
        subject: "",
        worksType: "",
        files: [],
        ownership: "1",
        obtainType: "1",
        authors: [
          {name:"",signatureType:"1",signatureName:"",orders:1},
        ],
        obtainTypeDesc: {
          text:"",
        },
        ownershipPicture: {
          "additionalProp1": "",
          "additionalProp2": "",
          "additionalProp3": "",
          "additionalProp4": "",
          "additionalProp5": "",
          "additionalProp6": "",
        },
        rightHave: [],
        extra: {
          processingTime: '15',
        },
      }
      this.fileList= [];
      this.fileList2= [];
    },
    // 下一步
    async next(){
      // localStorage.removeItem(`form_${this.mid}`); // 测试清理form缓存
        try {
          const formBaseResult = await this.$refs["form"].validate();
        } catch (error) {
          // 有错误,定位到错误
          // eslint-disable-next-line
          console.error(error);
          this.mixinsScrollView();
          return;
        }
        // console.log("lastform:",this.lastform);
        // console.log(this.form);
        this.stepIdx = '2'
        this.calAmount(); // 计算价格
        // this.$emit('getnext',6,this.form)
    },

    // 返回修改
    prev(){
      this.stepIdx = '1'
    },

    // 提交表单
    onSubmit(){
      let that = this;
      if (that.order) { // 如果已经提交过订单了，就阻止重复提交
        that.$refs.payDialog.showDialog(that.order);
        that.isPayDialogVisible = true;
        return false;
      }
      let params = {
        subject: that.form.subject,
        paymode: that.form.paymode,
        belong: that.form.ownership,
        // authors: that.form.author.map((t) => {
        //   return t.obligeeCode;
        // }),
        authors: that.form.authors,
        obligee: [that.form.right[0].obligeeCode],
        // obligee: that.form.right.map((t) => {
        //   return t.obligeeCode;
        // }),
        worksType: that.form.worksType,
        completeTime: Math.round(new Date(that.form.finishTime).getTime() / 1000),
        publishTime: Math.round(new Date(that.form.publishTime).getTime() / 1000),
        completeLocation: {
          province: that.form.province,
          city: that.form.city,
        },
        publishLocation: {
          province: that.form.province2,
          city: that.form.city2,
        },
        publishStatus: that.form.publishStatus,
        copyrights: that.form.rightHave,
        creationIntention: that.form.creationIntention,
        creationProperty: that.form.creationNature,
        creationOriginality: that.form.creationOriginality,
        creationPropertyDesc: that.form.creationPropertyDesc,
        items: that.form.files,
        obtainType: that.form.obtainType,
        obtainTypeDesc: that.form.obtainTypeDesc,
        ownershipPicture: that.form.ownershipPicture,
        signatureType: that.form.signatureType,
        extra: that.form.extra,
      };
      console.log(params);
      applyRegisterOrder(params).then(({ data }) => {
        that.order = data;
        console.log("order::::::::::::",that.order);
        if (data.paymentStatus == "21") {
          that.bus.$emit("refreshVip");
          that.onSetPayDialogClose(true);
          // console.log('登记-支付成功，删除本地储存form');
          localStorage.removeItem(`form_${this.mid}`);
        } else {
          that.$refs.payDialog.showDialog(data);
          that.isPayDialogVisible = true;
        }
      });
    },
    onSetPayDialogClose(result) {
      console.log(result);
      if (result) {
        //跳转至详情页
        this.$router.push({
          path: "/Register/Detail",
          query: { orderNo: this.order.orderNo },
        });
      }
    },

  },

};
</script>

<style lang='scss' scoped>
@import "../../../assets/css/inputForm.scss";
.commonWhiteBg {
  min-height: 800px;
  /deep/ .ant-popover-arrow {
    left: 30%;
  }
  .title {
    font-size: 24px;
    margin-bottom: 24px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .formTitle{
    font-family: 'PingFangSC, PingFang SC';
    font-weight: 500;
    font-size: 18px;
    color: rgba(0,18,22,0.85);
    line-height: 40px;
    padding: 0 15px;
    border-bottom: 1px solid #E2E5EA;
    position: relative;
  }
  .formTitle::before{
    content: '';
    width: 3px;
    height: 18px;
    background: #1C67E7;
    border-radius: 2px;
    position: absolute;
    left: 0;
    top: 11px;
  }
  .formTitle2::before{
    display: none;
  }
  .rightText{
    max-width: 140px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .el-form-item {
    margin: 12px 0;
    display: flex;
    align-items: inherit;
  }
  /deep/.el-form-item__label{
    font-family: 'PingFangSC, PingFang SC';
    font-weight: 500;
    font-size: 14px;
    color: rgba(0,18,22,0.85);
    line-height: 32px;
    padding-right: 12px;
  }
  /deep/.el-form-item__content{
    width: calc(100% - 125px);
    line-height: 32px;
    margin-left: 0!important;
    display: flex;
    align-items: inherit;
    .el-button{
      height: 32px;
      line-height: 32px;
      padding: 0;
    }
    .el-button:disabled{
      color: #C3C7CF;
      i{
        color: rgba(0,0,0,0.2);
      }
    }
    .el-input__inner{
      height: 32px;
      line-height: 32px;
      font-size: 14px;
      color: #001216;
      padding: 0 12px;
    }
    .el-input__inner:disabled{
      color: #C3C7CF;
    }
    .el-radio{
      height: 32px;
      line-height: 32px;
    }
    .el-select{
      height: 32px;
      line-height: 32px;
    }
    .el-input__icon{
      line-height: 32px;
    }
    .el-input__prefix {
        left: auto;
        right: 6px;
    }
    .el-radio__original {
      display: none !important;
    }

    .el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner {
      box-shadow: none !important;
    }
  }
  .el-upload{
    .el-button{
      padding: 0 12px;
      background: #FFFFFF;
      border-radius: 2px;
      border: 1px solid rgba(0,0,0,0.15);
      color: rgba(0,0,0,0.65);
      i{
        font-size: 14px;
        margin-right: 6px;
        color: rgba(0,0,0,0.45);
      }
    }
  }
  .tipsText{
    font-family: 'PingFangSC, PingFang SC';
    font-size: 14px;
    color: #89909B;
    line-height: 32px;
    padding: 0 10px;
  }
  /deep/.linkage div{
    width: calc(50% - 6px);
    height: 32px;
    .el-select{
      width: 100%;
      .el-input{
        width: 100%;
      }
    }
  }
  /deep/.el-textarea__inner{
    padding: 5px 12px;
  }
  .ant-alert-with-description.ant-alert-no-icon{
    padding: 5px 12px;
    margin: 12px 0 12px 12px;
  }
  .ant-alert-with-description{
    color: rgba(0, 18, 22, 0.50);
    /deep/.ant-alert-message{
      color: rgba(0, 18, 22, 0.65);
      margin-bottom: 2px;
    }
    /deep/.ant-alert-description{
      line-height: 20px;
    }
  }
  .bigPrice{
    font-size: 22px;
    line-height: 32px;
  }
  .submitRow{
    .el-button{
      width: 180px;
      margin-left: 125px;
    }
    .el-button:nth-child(2){
      margin-left: 40px;
    }
  }

}


// 适配移动端
@media screen and (max-width: 991px){
  /deep/.commonWhiteBg .el-form-item__content{
    display: block;
    .el-button{display: block;}
    .el-radio{height: auto;}
  }
}
</style>
