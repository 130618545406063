<template>
  <div>
    <el-dialog
      width="460px"
      center
      :title="title"
      :visible.sync="visible"
      @closed="onClose"
    >
      <div class="checkPersonList">

        <el-checkbox-group v-model="selectType">
          <el-checkbox
            v-for="(item,index) in options"
            :key="index"
            :label="index"
          > {{ item.obligeeType=='1'?item.userName:item.companyName}}</el-checkbox>
        </el-checkbox-group>
        <div class="btnWrap">
          <el-button
            v-if="JSON.stringify(linkObj)!='{}'"
            type="text"
            @click="goto(linkObj.path)"
          >{{linkObj.label}}</el-button>
          <el-button type="primary" @click="onSubmit">确认添加</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'choosePersonDialog',
  props: {
    // 类型
    listOpt: {
      type: Array,
      required: true
    },
    value: {
      type: [Number, String, Array],
    },
    title: {
      type: String,
      default: ''
    },
    linkObj: {
      type: Object,
      default: val => ({})
    }
  },
  data () {
    return {
      // 弹框是否显示
      visible: false,
      selectType: [],
      options: []
    }
  },
  watch: {
    // value: {
    //   handler (newVal, oldVal) {
    //     this.selectType = newVal;
    //   },
    //   immediate: true //第一次进来触发
    // },
  },
  created () {
    this.visible = true
    this.init()
  },
  mounted () {
    this.$nextTick(() => {
      this.options = _.cloneDeep(this.listOpt)
    })
  },
  methods: {
    init () {
      console.log("init choose..")
    },
    goto (path) {
      this.$router.push({
        path: path,
        query: {}
      });
    },
    onSubmit(){
      // 如果用户是‘M’，则只能选择一个著作权人
      const userInfo = localStorage.getItem('userInfo');
      if (userInfo) {
        const userType = JSON.parse(userInfo).type;
        // console.log('userType-------:', userType);
        if(userType == 'M'){ // N为旧版本登记，M为新版本登记
        // if(userType == 'N'){ // N为旧版本登记，M为新版本登记
          if(this.selectType.length > 1){
            this.$message.error('只能选择一个著作权人!');
            return false
          }

          // 检查是否有电子签名/印章，没有的话，跳转到个人中心-著作权人管理里面 进行添加
          let obligeeInfo = this.listOpt[this.selectType[0]]
          if(!obligeeInfo.supportingMaterial || obligeeInfo.supportingMaterial.signature == ''){ // 如果没有电子签名/印章
            this.$confirm('<p>该著作权人没有还上传电子签名/印章。</p><p>是否去添加?</p>', '提示', {
              dangerouslyUseHTMLString: true, // 允许使用 HTML
              confirmButtonText: '确定',
              cancelButtonText: '取消',
            }).then(() => {
              // 用户点击确定后的操作
              this.$router.push({path: '/Mine/Obligee',query: {uniqueCode: obligeeInfo.uniqueCode},}) // 使用路由跳转
            }).catch(() => {
              // 用户点击取消     
            })
            return false
          }

        }
        
      }
      let arr=[];
        for (let i = 0; i < this.selectType.length; i++) {
          const element = this.selectType[i];
          arr.push(this.listOpt[element*1]);
        }
          this.$emit('input',arr);
          this.onClose()
    },
    // 关闭弹框时触发的事件
    onClose () {
      this.$emit('close')
    }
  }
}
</script>

<style lang='scss' scoped>
.checkPersonList {
  .btnWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
/deep/ .el-dialog--center .el-dialog__body{
    padding-top:0;
}
/deep/.el-button{
  padding: 0 20px!important;
  height: 32px;
  line-height: 32px;
}
</style>
